/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
// @mui material components
import Card from "@mui/material/Card";

// @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import GitHubIcon from "@mui/icons-material/GitHub";
// import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { Oval } from 'react-loader-spinner';
import * as service from '../../../../services/service';

function Basic() {
  sessionStorage.clear();
  const [state, setState] = useState({ email: '', password: '' });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const validate = () => {
    let isValid = true;
    const errs = {};
    if (!state.email) {
      isValid = false;
      errs[".email"] = "Please enter email";
    }
    // if (state.email !== "undefined") {
    //   const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    //   if (!pattern.test(state.email)) {
    //     isValid = false;
    //     errs[".email"] = "Please enter valid email address";
    //   }
    // }
    if (!state.password) {
      isValid = false;
      errs[".password"] = "Please enter password";
    }
    setErrors(errs);
    return isValid;
  }

  const submitHandler = (event) => {
    event.preventDefault();
    const errs = {};
    setLoading(true);
    if (validate()) {
      fetch(
        service.LOGIN_URL,
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            EmailId: state.email,
            Password: state.password,
          })
        })
        .then(data => data.json())
        .then(userData => {
          if (userData.message.messageType === 'Success') {
            const returnObj = userData;
            sessionStorage.setItem('userData', JSON.stringify(returnObj));
            
            // setState({ email: "", password: "" })
            navigate('/dashboard');
          }
          else {
            errs[".invalid"] = userData.message.message;
            setErrors(errs);
          }
        });
    }
    setLoading(false);    
  };

  const changeHandler = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>          
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
            <MDInput type="email" label="Email" fullWidth value={state.email || ''}
                name="email"
                onChange={changeHandler}
                id="email"
                required />
              <div style={{ fontSize: '12px', color: 'red' }}>{errors[".email"]}</div>
            </MDBox>
            <MDBox mb={2}>
            <MDInput type="password" label="Password" fullWidth value={state.password || ''}
                name="password"
                onChange={changeHandler}
                id="password"
                required />
              <div style={{ fontSize: '12px', color: 'red' }}>{errors[".password"]}</div>
            </MDBox>            
            <MDBox mt={4} mb={1}>
            {loading ? <Oval
                heigth="20"
                width="20"
                color='grey'
                ariaLabel='loading'
              /> :
              <MDButton variant="gradient" color="info" fullWidth onClick={submitHandler}>
                sign in
              </MDButton>
            }
            {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
            </MDBox>            
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
