
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
// import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import { TextField } from "@mui/material";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
// import { Document, Text, StyleSheet, View, Image } from '@react-pdf/renderer';
import * as moment from 'moment';
import certLogo from "assets/images/CertificateLogo.png";
import satyaLogo from "assets/images/CertificateLogoOld.png";
import { jsPDF } from 'jspdf';
import html2canvas from "html2canvas";
import * as service from '../../services/service';

function GenerateAgroCertificate() {
    const [data, setData] = useState([{}]);
    const [roomNumber, setRoomNumber] = useState('');
    const [tentNumber, setTentNumber] = useState('');
    const [population, setPopulation] = useState('');
    const [villageName, setVillageName] = useState('');
    const [surveyNumber, setSurveyNumber] = useState('');
    const [area, setArea] = useState('');
    const [centerType, setCenterType] = useState('');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const certificateId = JSON.parse(sessionStorage.getItem('CertificateId'));
    const certificateNumber = JSON.parse(sessionStorage.getItem('CertificateNumber'));
    const outwardNumber = JSON.parse(sessionStorage.getItem('OutwardNumber'));
    const validFrom = JSON.parse(sessionStorage.getItem('ValidFrom'));
    const validTill = JSON.parse(sessionStorage.getItem('ValidTill'));
    const issueDate = JSON.parse(sessionStorage.getItem('IssueDate'));
    const navigate = useNavigate();
    const params = useParams();

    const getRegistrationById = () => {
        setLoading(true);
        // const errs = {};        
        if (params.id > 0) {
            fetch(
                service.GET_AGRO_TOUR_REGISTRATION_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        AgroRegistrationId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        setData(result);
                    }
                    else {
                        // eslint-disable-next-line
                        alert(result.message.message);
                    }
                });
        }
        setLoading(false);
    };

    // const getDocumentsById = () => {
    //     setLoading(true);
    //     // const errs = {};        
    //     if (params.id > 0) {
    //         fetch(
    //             service.GET_AGRO_TOUR_DOCUMENTS_BY_ID,
    //             {
    //                 method: 'POST',
    //                 headers: {
    //                     'Accept': 'application/json',
    //                     'Content-Type': 'application/json'
    //                 },
    //                 body: JSON.stringify({
    //                     AgroRegistrationId: params.id,
    //                 })
    //             })
    //             .then(res => res.json())
    //             .then(result => {
    //                 if (result[0].message.messageType === 'Success') {
    //                     setDocument(result);
    //                 }
    //                 else {
    //                     // eslint-disable-next-line
    //                     alert(result.message.message);
    //                 }
    //             });
    //     }
    //     setLoading(false);
    // };

    const getSiteVisitById = () => {
        setLoading(true);
        // const errs = {};        
        if (params.id > 0) {
            fetch(
                service.GET_AGRO_TOUR_SITE_VISIT_DETAILS,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        AgroRegistrationId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    console.log(result);
                    if (result[0].message.messageType === 'Success') {
                        // setSiteVisit(result);
                        const room = result.filter(obj => obj.criteriaId === 257); // 'कृषी पर्यटन केंद्रातील एकूण खोल्यांची संख्या'                       
                        setRoomNumber(room[0] ? room[0].remarks : '');

                        const tent = result.filter(obj => obj.criteriaId === 258); // 'तंबू निवास संख्या'
                        setTentNumber(tent[0] ? tent[0].remarks : '');

                        const pop = result.filter(obj => obj.criteriaId === 259); // 'लोक निवास संख्या'
                        setPopulation(pop[0] ? pop[0].remarks : '');

                        const vill = result.filter(obj => obj.criteriaId === 260);  //  'महसूली गावाचे नाव'                      
                        setVillageName(vill[0] ? vill[0].remarks : '');

                        const survey = result.filter(obj => obj.criteriaId === 261); // 'सर्वे / गट नंबर'
                        setSurveyNumber(survey[0] ? survey[0].remarks : '');

                        const are = result.filter(obj => obj.criteriaId === 262); // 'जमिनीचे क्षेत्रफळ (एकर)'
                        setArea(are[0] ? are[0].remarks: '');

                        const type = result.filter(obj => obj.criteriaId === 263); //  'कृषी पर्यटन केंद्राचा प्रकार ( निवासी की अनिवासी )'
                        setCenterType(type[0] ? type[0].remarks: '');
                    }
                    else {
                        // eslint-disable-next-line
                        alert(result.message.message);
                    }
                });
        }
        setLoading(false);
    };

    useEffect(() => {
        if (params.id > 0) {
            getRegistrationById();
            // getDocumentsById();
            getSiteVisitById();
        }
    }, []);


    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        const divToPrint = document.querySelector('#divForm');
        html2canvas(divToPrint).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const imgWidth = 190;
            const pageHeight = 290;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            // eslint-disable-next-line new-cap
            const doc = new jsPDF('pt', 'mm');
            let position = 0;
            doc.addImage(imgData, 'PNG', 10, 0, imgWidth, imgHeight + 25, undefined, 'FAST');
            heightLeft -= pageHeight;
            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight + 25);
                heightLeft -= pageHeight;
            }
            // const doc1 = addWaterMark(doc);
            const blob = doc.output('blob');
            // doc.toBlob(blob => {
            const certFile = new File([blob], 'Certificate.pdf');
            const formData = new FormData();
            formData.append("AgroRegistrationId", params.id ? params.id : null);
            formData.append("AgroCertificateId", certificateId);
            formData.append("IssueDate", (new Date(issueDate)).toUTCString());
            formData.append("ValidFrom", (new Date(validFrom)).toUTCString());
            formData.append("ValidTill", (new Date(validTill)).toUTCString());
            formData.append("CertificateNumber", certificateNumber);
            formData.append("OutwardNumber", outwardNumber);
            formData.append("CertificateFile", certFile);
            formData.append("AddedBy", JSON.parse(window.sessionStorage.getItem('userData')).userId);
            fetch(
                service.ADD_UPDATE_AGRO_CERTIFICATE,
                {
                    method: 'POST',
                    body: formData
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        sessionStorage.setItem('CertificateNumber', null);
                        sessionStorage.setItem('ValidFrom', null);
                        sessionStorage.setItem('ValidTill', null);
                        sessionStorage.setItem('IssueDate', null);
                        sessionStorage.setItem('OutwardNumber', null);
                        sessionStorage.setItem('CertificateId', null);
                        navigate('/agrotourregistrations');
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                        sessionStorage.setItem('CertificateNumber', null);
                        sessionStorage.setItem('ValidFrom', null);
                        sessionStorage.setItem('ValidTill', null);
                        sessionStorage.setItem('IssueDate', null);
                        sessionStorage.setItem('OutwardNumber', null);
                        sessionStorage.setItem('CertificateId', null);
                        navigate('/agrotourregistrations');
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        });
        // }, 'image/png')
        setLoading(false);
    }

    const backHandler = () => {
        navigate('/agrotourregistrations');
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Certificate
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                {/* <Document style={{ width: '100%' }}> */}
                                <div id="divForm" width="100%">
                                    <br />
                                    <br />
                                    <Table id="tblCertificate" style={{ "borderWidth": "5px", 'borderColor': "lightcoral", 'borderStyle': 'solid' }}>
                                        <TableRow>
                                            <TableCell align="left">
                                                <br />
                                                <img src={satyaLogo} alt="Logo" height="200px" width="200px" />
                                            </TableCell>
                                            <TableCell align="right">
                                                <br />
                                                <img src={certLogo} alt="Logo" height="200px" width="200px" />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan="2" align="center" style={{ fontSize: '22px', fontWeight: 'bold', color: 'maroon' }}>
                                                पर्यटन संचालनालय
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan="2" align="center" style={{ fontSize: '18px', fontWeight: 'bold', color: 'maroon' }}>
                                                कृषी पर्यटन केंद्र
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan="2" align="center" style={{ fontSize: '20px', fontWeight: 'bold', color: 'maroon' }}>
                                                नोंदणी प्रमाणपत्र (Registration Certificate)
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                नोंदणी क्रमांक (Registration No.)
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                : {certificateNumber}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                विभागीय कार्यालय (Divisional Office)
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                : Deputy Director, Tourism - {data.region} Region
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                कृषी पर्यटन नोंदणीधारक श्री /श्रीमती /सौ (Applicant`s Name Shri/Smt/Organization)
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                : {data.fullName}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan="2" style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                यांना पर्यटकांसाठी `कृषी पर्यटन`` योजने अंतर्गत खालील कृषी पर्यटन केंद्र चालविण्याची परवानगी देण्यात येत आहे.
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan="2" style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                कृषी पर्यटन केंद्राचा तपशील -
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                कृषी पर्यटन केंद्राचे नाव
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                : {data.companyName}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                कृषी पर्यटन केंद्राचा पत्ता (Agro Tourism Center Address)
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                : {data.centerAddress ? data.centerAddress : data.applicantAddress}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan="2">
                                                <Table>
                                                    <TableRow>
                                                        <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }} width="20%">
                                                            एकूण खोल्यांची संख्या
                                                        </TableCell>
                                                        <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }} width="15%">
                                                            : {roomNumber}
                                                        </TableCell>
                                                        <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }} width="20%">
                                                            लोकनिवास संख्या
                                                        </TableCell>
                                                        <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }} width="15%">
                                                            : {population}
                                                        </TableCell>
                                                        <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }} width="20%">
                                                            तंबू निवास
                                                        </TableCell>
                                                        <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                            : {tentNumber}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                            महसुली गावाचे नाव
                                                        </TableCell>
                                                        <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                            : {villageName}
                                                        </TableCell>
                                                        <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                            सर्वे /गट क्र
                                                        </TableCell>
                                                        <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                            : {surveyNumber}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                            जमिनीचे क्षेत्रफळ (एकर)
                                                        </TableCell>
                                                        <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                            : {area}
                                                        </TableCell>
                                                        <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                            कृषी पर्यटन केंद्राचा प्रकार
                                                        </TableCell>
                                                        <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                            : {centerType}
                                                        </TableCell>
                                                    </TableRow>
                                                </Table>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                प्रथम प्रमाणपत्र मुदत वर्ष (registration valid for the period of)
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                : {moment(validFrom).format('DD-MM-YYYY')} To {moment(validTill).format('DD-MM-YYYY')}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan="2" style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                Issue Date: {moment(issueDate).format('DD-MM-YYYY')}
                                            </TableCell>
                                        </TableRow>
                                        {/* <TableRow>
                                            <TableCell id="tcSignature" colSpan="2" align="right" style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                <img src={signatureImage} alt="sign" width="180px" />
                                            </TableCell>
                                        </TableRow> */}
                                        <TableRow>
                                            <TableCell colSpan="2" align="right" style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                <br />
                                                <br />
                                                {JSON.parse(sessionStorage.getItem('userData')).userName}
                                                <br />
                                                <br />
                                            </TableCell>
                                        </TableRow>
                                        {/* <TableRow>
                                            <TableCell colSpan="2" align="right" style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                <img src={stampImage} alt="stamp" width="180px" />
                                            </TableCell>
                                        </TableRow> */}
                                    </Table>
                                </div>
                                <Table>
                                    <TableRow>
                                        <TableCell>
                                            {loading ? <Oval
                                                heigth="20"
                                                width="20"
                                                color='grey'
                                                ariaLabel='loading'
                                            /> :
                                                <MDButton color="info" onClick={submitHandler}>
                                                    Save
                                                </MDButton>
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <MDButton color="secondary" onClick={backHandler}>
                                                Back
                                            </MDButton>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".error"]}</div>
                                {/* </Document> */}
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default GenerateAgroCertificate;