/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
// import Icon from "@mui/material/Icon";
// Authentication layout components
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as moment from 'moment';
import { Oval } from 'react-loader-spinner';
import { jsPDF } from 'jspdf';
import html2canvas from "html2canvas";
import * as service from '../../services/service';

function ViewAgroTourRegistrationDetails() {
    const [state, setState] = useState(
        {
            remarks: ''
        });
    const [data, setData] = useState([{}]);
    const [documentData, setDocuemntData] = useState([{}]);
    const [loading, setLoading] = useState(false);
    const userRl = JSON.parse(sessionStorage.getItem('userData')).userRole;

    const navigate = useNavigate();
    const params = useParams();

    const getRegistrationById = () => {
        setLoading(true);
        // const errs = {};        
        if (params.id > 0) {
            fetch(
                service.GET_AGRO_TOUR_REGISTRATION_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        AgroRegistrationId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        setData(result);
                        setState({ ...state, remarks: result.remarks });
                    }
                    else {
                        // eslint-disable-next-line
                        alert(result.message.message);
                    }
                });
        }
        setLoading(false);
    };

    const getDocumentsById = () => {
        setLoading(true);
        // const errs = {};        
        if (params.id > 0) {
            fetch(
                service.GET_AGRO_TOUR_DOCUMENTS_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        AgroRegistrationId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result[0].message.messageType === 'Success') {
                        setDocuemntData(result);
                    }
                    else {
                        // eslint-disable-next-line
                        alert(result.message.message);
                    }
                });
        }
        setLoading(false);
    };
    useEffect(() => {
        setLoading(true);
        getRegistrationById();
        getDocumentsById();
        setLoading(false);
    }, []);

    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const backHandler = () => {
        navigate('/agrotourregistrations');
    };

    const handleDownload = (fileName, filePath) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("FileName", fileName);
        formData.append("FilePath", filePath);
        fetch(
            service.DOWNLOAD_FILE,
            {
                method: 'POST',
                body: formData
            })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    fileName,
                );
                // Append to html link element page
                document.body.appendChild(link);
                // Start download
                link.click();
                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });

        setLoading(false);
    };

    const submitHandler = (regStatus) => {
        // e.preventDefault();
        const errs = {};
        setLoading(true);
        // eslint-disable-next-line
        if (window.confirm("Are you sure...?")) {
            fetch(
                service.UPDATE_AGRO_TOUR_REGISTRATION_VERIFICATION,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        AgroRegistrationId: params.id,
                        Status: regStatus,
                        Remarks: state.remarks
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        navigate('/agrotourregistrations');
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                        // navigate('/agrotourregistrations');
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        // setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }

    const verifyDocumentHandler = (id, docStatus) => {
        const errs = {};
        setLoading(true);
        // eslint-disable-next-line
        if (window.confirm("Are you sure...?")) {
            fetch(
                service.UPDATE_AGRO_TOUR_DOCUMENT_VERIFICATION,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        AgroDocumentId: id,
                        Status: docStatus,
                        VerifiedBy: JSON.parse(window.sessionStorage.getItem('userData')).userId,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        window.location.reload();
                        // navigate('/agrotourregistrations');
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                        // navigate('/agrotourregistrations');
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        // setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }
    const printDocument = () => {
        setTimeout(() => {
            setTimeout(() => {
                setLoading(true);
            }, 100);
            const divToPrint = document.querySelector('#divForm');
            html2canvas(divToPrint)
                .then((canvas) => {
                    const imgData = canvas.toDataURL('image/png');
                    const imgWidth = 190;
                    const pageHeight = 290;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    let heightLeft = imgHeight;
                    // eslint-disable-next-line new-cap
                    const doc = new jsPDF('pt', 'mm');
                    let position = 0;
                    doc.addImage(imgData, 'PNG', 10, 0, imgWidth, imgHeight + 25);
                    heightLeft -= pageHeight;
                    while (heightLeft >= 0) {
                        position = heightLeft - imgHeight;
                        doc.addPage();
                        doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight + 25);
                        heightLeft -= pageHeight;
                    }
                    doc.save('ApplicationForm.pdf');
                    setLoading(false);
                });
        }, 1000);
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div style={{ width: '100%' }}>
                <Card>
                    <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                        mx={2}
                        mt={-3}
                        p={2}
                        mb={1}
                        textAlign="center"
                    >
                        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                            Agro Tour Registration Details
                        </MDTypography>
                    </MDBox>
                    {loading ? <Oval
                        heigth="100"
                        width="100"
                        color='grey'
                        ariaLabel='loading'
                    /> :
                        <MDBox pt={4} pb={3} px={3}>
                            <MDBox component="form" role="form">
                                {/* <div style={{ fontSize: '12px', color: 'red' }}>{errors}</div> */}
                                <Card style={{ alignItems: 'center' }}>
                                    <MDBox pt={4} pb={3} px={3} style={{ width: '100%' }}>
                                        <div id="divForm" width="100%">
                                            <TableContainer component={Paper} style={{ width: '100%', alignSelf: 'center' }}>
                                                <Table id="tblReceipt" style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell colSpan={2}>
                                                                <Table style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Email Id :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.emailId}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Mobile No. :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.mobileNo}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Registration Date :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {moment(data.addedDate).format("DD/MM/YYYY")}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell colSpan="2" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Full Name of Applicant/Farmer/Company/Firm/Farmer Co-operative Society And Authorized Person :
                                                                        </TableCell>
                                                                        <TableCell colSpan="4" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.fullName}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell colSpan="2" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Name of Agro Tourism Center :
                                                                        </TableCell>
                                                                        <TableCell colSpan="4" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.companyName}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Applicant Type :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.applicantType}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Full Address of Applicant :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.applicantAddress}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Full Address of Agrotorism Center if Different from Applicant Address :
                                                                        </TableCell>
                                                                        <TableCell colSpan="3" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.centerAddress}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Region :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.region}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            District :
                                                                        </TableCell>
                                                                        <TableCell colSpan="3" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.district}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell colSpan="2" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Description of Complete Agricultural land Available for Agro-tourists under the Scheme (village, area of land(per acre), number of rooms and beds as well as other facilities (toilets / bathrooms / telephone etc.))  :
                                                                        </TableCell>
                                                                        <TableCell colSpan="4" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.landDescription}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell colSpan="6" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Type of Agro-tourism Center: Details of Additional Facilities if Available :
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Day Trip Service :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.dayTripService ? "Yes" : "No"}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Accomodation :
                                                                        </TableCell>
                                                                        <TableCell colSpan="3" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.accomodation ? "Yes" : "No"}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell colSpan="3" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Recreational Service
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell width="20%" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Play Area For Children :
                                                                        </TableCell>
                                                                        <TableCell width="20%" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.playArea ? "Yes" : "No"}
                                                                        </TableCell>
                                                                        <TableCell width="15%" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Adventure Games :
                                                                        </TableCell>
                                                                        <TableCell width="20%" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.adventureGames ? "Yes" : "No"}
                                                                        </TableCell>
                                                                        <TableCell width="15%" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Rural Games :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.ruralGames ? "Yes" : "No"}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Agricultural Camping :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.agriculturalCamping ? "Yes" : "No"}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Horticulture and Product Sale :
                                                                        </TableCell>
                                                                        <TableCell colSpan="3" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.horticultureSale ? "Yes" : "No"}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Do Applicant live in Agro-tourism place? :
                                                                        </TableCell>
                                                                        <TableCell colSpan="5" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.liveInPlace ? "Yes" : "No"}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell colSpan="6" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Other Activities Related to Agriculture
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Green House :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.greenHouse ? "Yes" : "No"}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Milk Business :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.milkBusiness ? "Yes" : "No"}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Fisheries :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.fisheries ? "Yes" : "No"}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            RopVatika :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.ropVatikaNursery ? "Yes" : "No"}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Animal-Bird Rearing :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.animalBirdRearing ? "Yes" : "No"}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Nature Adventure Tourism :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.natureAdventureTourism ? "Yes" : "No"}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Other :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.other ? "Yes" : "No"}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell colSpan="2" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            If Agro-tourism Center already exists, Information on when it started  :
                                                                        </TableCell>
                                                                        <TableCell colSpan="2" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.centerStarted}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Status :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.status}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </Table>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                                <Table>
                                                    <TableRow>
                                                        <TableCell colSpan="6" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            Documents
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            Document Name
                                                        </TableCell>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            File Name
                                                        </TableCell>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            Status
                                                        </TableCell>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            Verification Date
                                                        </TableCell>
                                                        {userRl === "DTO" ?
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Approve
                                                            </TableCell>
                                                            : null}
                                                        {userRl === "DTO" ?
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Reject
                                                            </TableCell>
                                                            : null}
                                                    </TableRow>
                                                    <TableBody>
                                                        {documentData.map((row) => (
                                                            <TableRow
                                                                key={row.agroDocumentId}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                    <MDButton onClick={() => handleDownload(row.fileName, row.filePath)}>{row.documentName}</MDButton>
                                                                </TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.fileName}</TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.status}</TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.verificationDate ? moment(row.verificationDate).format("DD/MM/YYYY") : ''}</TableCell>
                                                                {userRl === "DTO" ?
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        <MDButton onClick={() => verifyDocumentHandler(row.agroDocumentId, "Approved")}>Approved</MDButton>
                                                                    </TableCell>
                                                                    : null}
                                                                {userRl === "DTO" ?
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        <MDButton onClick={() => verifyDocumentHandler(row.agroDocumentId, "Rejected")}>Rejected</MDButton>
                                                                    </TableCell>
                                                                    : null}
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                    <TableRow>
                                                        <TableCell colSpan="6" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            <MDInput label="Remarks" fullWidth value={state.reamrks}
                                                                name="remarks"
                                                                onChange={changeHandler}
                                                                id="remarks"
                                                                multiline
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </MDBox>
                                    <MDBox mt={4} mb={1} textAlign="center">
                                        <Table>
                                            <TableRow>
                                                {userRl === "DTO" ?
                                                    <TableCell>
                                                        <MDButton color="info" onClick={() => submitHandler("Approved")}
                                                            disabled={data.status === "Approved" || data.documentStatus === "Rejected"}>
                                                            Approved
                                                        </MDButton>
                                                    </TableCell>
                                                    : null}
                                                {userRl === "DTO" ?
                                                    <TableCell>
                                                        <MDButton color="info" onClick={() => submitHandler("Rejected")} disabled={data.status === "Rejected"}>
                                                            Rejected
                                                        </MDButton>
                                                    </TableCell>
                                                    : null}
                                                <TableCell>
                                                    <MDButton color="info" onClick={() => printDocument()}>
                                                        PRINT
                                                    </MDButton>
                                                </TableCell>
                                                <TableCell>
                                                    <MDButton color="secondary" onClick={backHandler}>
                                                        Back
                                                    </MDButton>
                                                </TableCell>
                                            </TableRow>
                                        </Table>
                                    </MDBox>
                                </Card>
                            </MDBox>
                        </MDBox>

                    }
                </Card>
            </div>
        </DashboardLayout>
    );
}

export default ViewAgroTourRegistrationDetails;
