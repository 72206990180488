import { useNavigate, useParams } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
// import MDAlert from "components/MDAlert";
import MDButton from 'components/MDButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
import { Oval } from 'react-loader-spinner';
import Select from 'react-select';
import MDInput from "components/MDInput";
import * as service from '../../services/service';

function AccomodationSiteVisit() {
    const [data, setData] = useState([]);
    const [criteriaIds, setCriteriaIds] = useState([]);
    const [remarks, setRemarks] = useState([]);
    const [status, setStatus] = useState([{}]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [siteStatus, setSiteStatus] = useState('');
    const statusData = [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }];
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const userRl = JSON.parse(sessionStorage.getItem('userData')).userRole;

    const navigate = useNavigate();
    const params = useParams();

    const getSiteVisitDetails = () => {
        fetch(
            service.GET_ACCOMODATION_SITE_VISIT_DETAILS,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    AccomodationId: params.id,
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result[0].message.messageType === "Failed") {
                    fetch(
                        service.GET_SITE_VISIT_CRITERIA_BY_TYPE,
                        {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                TourType: "Industrial Status Accomodation",
                            })
                        })
                        .then(res => res.json())
                        .then(result1 => {
                            setData(result1);
                            result1.forEach((element, index) => {
                                criteriaIds[index] = element.criteriaId;
                                setCriteriaIds([...criteriaIds]);
                                remarks[index] = '';
                                setRemarks([...remarks]);
                                status[index] = { label: 'Select Status', value: 'Select Status' };
                                setStatus([...status]);
                            });
                        })
                        .catch(err => {
                            if (err) {
                                setErrors(err);
                            };
                        });
                }
                else {
                    setData(result);
                    setSiteStatus(result[0].siteStatus);
                    result.forEach((element, index) => {
                        criteriaIds[index] = element.criteriaId;
                        setCriteriaIds([...criteriaIds]);
                        remarks[index] = element.remarks;
                        setRemarks([...remarks]);
                        status[index] = { label: element.status, value: element.status };
                        setStatus([...status]);
                    });
                }
            })
            .catch(err => {
                if (err) {
                    setErrors(err);
                };
            });
    };

    const submitHandler = (e, siteSt) => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        let flag = true;
        fetch(
            service.DELETE_ACCOMODATION_SITE_VISIT_DETAILS,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    AccomodationId: params.id,
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.messageType === 'Failed') {
                    // eslint-disable-next-line
                    alert(result.message);
                    // navigate('/subassemblymaterialmap');
                }
                else {
                    errs[".invalid"] = result.message.message;
                    setErrors(errs);
                }
            });

        criteriaIds.forEach((element, index) => {
            fetch(
                service.ADD_ACCOMODATION_SITE_VISIT_DETAILS,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        AccomodationId: params.id,
                        CriteriaId: element,
                        Remarks: remarks[index],
                        Status: status[index].value,
                        SiteStatus: siteSt,
                        AddedBy: JSON.parse(window.sessionStorage.getItem('userData')).userId
                    })
                })
                .then(resp => resp.json())
                .then(respo => {
                    if (respo.messageType === 'Failed') {
                        flag = false;
                        // eslint-disable-next-line
                        alert(respo.message);
                    }
                    else {
                        flag = false;
                        errs[".invalid"] = respo.message.message;
                        setErrors(errs);
                    }
                });

        });


        if (flag) {
            // eslint-disable-next-line
            alert("Data Saved Successfully");
            navigate('/accomodationregistrations');
        }
        setLoading(false);
    }
    useEffect(() => {
        setLoading(true);
        getSiteVisitDetails();
        setLoading(false);
    }, []);

    const remarkChangeHandler = (event, index) => {
        let updatedObject = remarks[index];
        updatedObject = event.target.value;
        remarks[index] = updatedObject;
        setRemarks([...remarks]);
    };
    const statusChangeHandler = (event, index) => {
        let updatedObject = status[index];
        updatedObject = event;
        status[index] = updatedObject;
        setStatus([...status]);
    };
    const backHandler = () => {
        navigate('/accomodationregistrations');
    };
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Industrial Status Accomodation Site Visit Details
                                </MDTypography>
                            </MDBox>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                <MDBox mb={2} style={{ fontSize: '12px' }} />
                                {loading ? <Oval
                                    heigth="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :
                                    // <TableContainer component={Paper} style={{ width: '90%', alignSelf: 'center', }}>
                                    <Table aria-label="simple table" style={{ width: '90%', alignSelf: 'center', }} >
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold' }}>Criteria</TableCell>
                                            <TableCell style={{ fontWeight: 'bold' }}>Remarks</TableCell>
                                            <TableCell style={{ fontWeight: 'bold' }}>Status</TableCell>
                                        </TableRow>
                                        <TableBody>
                                            {data.map((row, index) => (
                                                <TableRow
                                                    key={row.criteriaId}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell width="35%">{row.criteria}</TableCell>
                                                    <TableCell width="35%">
                                                        <MDInput label="Remarks" fullWidth value={remarks[index] || ''}
                                                            name="remarks"
                                                            onChange={ev => remarkChangeHandler(ev, index)}
                                                            id="remarks"
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".remarks"]}</div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Select
                                                            options={statusData}
                                                            name="status"
                                                            value={status[index]}
                                                            onChange={ev => statusChangeHandler(ev, index)}
                                                            placeholder="Select Status"
                                                            isSearchable
                                                            styles={selectStyles}
                                                        />
                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".status"]}</div>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>Site Visit Status : {siteStatus} </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    // </TableContainer>
                                }
                                <MDBox mt={4} mb={1} textAlign="center">
                                    <Table>
                                        <TableRow>
                                            {userRl === "DTO" ?
                                                <TableCell>
                                                    {loading ? <Oval
                                                        heigth="20"
                                                        width="20"
                                                        color='grey'
                                                        ariaLabel='loading'
                                                    /> :
                                                        <MDButton color="info" onClick={e => submitHandler(e, "Approved")}
                                                            disabled={siteStatus === "Approved"}>
                                                            Approved
                                                        </MDButton>
                                                    }
                                                </TableCell>
                                                : null}
                                            {userRl === "DTO" ?
                                                <TableCell>
                                                    {loading ? <Oval
                                                        heigth="20"
                                                        width="20"
                                                        color='grey'
                                                        ariaLabel='loading'
                                                    /> :
                                                        <MDButton color="info" onClick={e => submitHandler(e, "Rejected")}
                                                            disabled={siteStatus === "Rejected"}>
                                                            Rejected
                                                        </MDButton>
                                                    }
                                                </TableCell>
                                                : null}
                                            {userRl === "DTO" ?
                                                <TableCell>
                                                    {loading ? <Oval
                                                        heigth="20"
                                                        width="20"
                                                        color='grey'
                                                        ariaLabel='loading'
                                                    /> :
                                                        <MDButton color="info" onClick={e => submitHandler(e, "In Process")}>
                                                            In Process
                                                        </MDButton>
                                                    }
                                                </TableCell>
                                                : null}
                                            {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}

                                            <TableCell>
                                                <MDButton color="secondary" onClick={backHandler}>
                                                    Back
                                                </MDButton>
                                            </TableCell>
                                        </TableRow>
                                    </Table>
                                </MDBox>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout>
    );
}
export default AccomodationSiteVisit;