
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
// import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import { TextField } from "@mui/material";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
// import { Document, Text, StyleSheet, View, Image } from '@react-pdf/renderer';
import * as moment from 'moment';
import certLogo from "assets/images/CertificateLogo.png";
import { jsPDF } from 'jspdf';
import html2canvas from "html2canvas";
import * as service from '../../services/service';

function GenerateLandCertificate() {
    const [data, setData] = useState([{}]);
    const [advDoc, setDocument] = useState([{}]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const certificateId = JSON.parse(sessionStorage.getItem('CertificateId'));
    const certificateNumber = JSON.parse(sessionStorage.getItem('CertificateNumber'));
    const outwardNumber = JSON.parse(sessionStorage.getItem('OutwardNumber'));
    const validFrom = JSON.parse(sessionStorage.getItem('ValidFrom'));
    const validTill = JSON.parse(sessionStorage.getItem('ValidTill'));
    const issueDate = JSON.parse(sessionStorage.getItem('IssueDate'));
    // const signature = JSON.parse(sessionStorage.getItem('userData')).signatureFilePath
    // const stamp = JSON.parse(sessionStorage.getItem('userData')).stampFilePath
    // const signatureImage = `data:image/png;base64,${signature}`;
    // const stampImage = `data:image/png;base64,${stamp}`;
    const navigate = useNavigate();
    const params = useParams();

    const getRegistrationById = () => {
        setLoading(true);
        // const errs = {};        
        if (params.id > 0) {
            fetch(
                service.GET_ADVENTURE_TOUR_REGISTRATION_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        AdventureRegistrationId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        setData(result);
                    }
                    else {
                        // eslint-disable-next-line
                        alert(result.message.message);
                    }
                });
        }
        setLoading(false);
    };

    const getDocumentsById = () => {
        setLoading(true);
        // const errs = {};        
        if (params.id > 0) {
            fetch(
                service.GET_ADVENTURE_TOUR_DOCUMENTS_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        AdventureRegistrationId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result[0].message.messageType === 'Success') {
                        const unique = [];
                        result.forEach(element => {
                            const findItem = unique.find((x) => x.documentType === element.documentType);
                            if (!findItem) unique.push(element);
                        });
                        setDocument(unique);
                        // setDocument(result);
                    }
                    else {
                        // eslint-disable-next-line
                        alert(result.message.message);
                    }
                });
        }
        setLoading(false);
    };

    useEffect(() => {
        if (params.id > 0) {
            getRegistrationById();
            getDocumentsById();
        }
    }, []);

    // function addWaterMark(doc) {
    //     // const totalPages = doc.internal.getNumberOfPages();
    //     // let i = 1;
    //     // while (i <= totalPages) {           
    //     // doc.setPage(i);
    //     doc.setPage(1);
    //     doc.setTextColor(200);
    //     doc.text('Provisional Certificate', 70, doc.internal.pageSize.height - 70, { 'angle': '50' }, 45);
    //     // i += 1;
    //     // }
    //     return doc;
    // }

    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        const divToPrint = document.querySelector('#divForm');
        html2canvas(divToPrint).then(canvas => {
            // const extraCanvas = document.createElement("canvas");
            // extraCanvas.setAttribute('width', 700);
            // extraCanvas.setAttribute('height', 700);            
            // const ctx = extraCanvas.getContext('2d');
            // ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, 700, 700)

            const imgData = canvas.toDataURL('image/png');
            const imgWidth = 190;
            const pageHeight = 290;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            // eslint-disable-next-line new-cap
            const doc = new jsPDF('pt', 'mm');
            let position = 0;
            doc.addImage(imgData, 'PNG', 10, 0, imgWidth, imgHeight + 25, undefined, 'FAST');
            heightLeft -= pageHeight;
            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight + 25);
                heightLeft -= pageHeight;
            }
            // const doc1 = addWaterMark(doc);
            const blob = doc.output('blob');
            // doc.toBlob(blob => {
            const certFile = new File([blob], 'Certificate.pdf');
            const formData = new FormData();
            formData.append("AdventureRegistrationId", params.id ? params.id : null);
            formData.append("AdventureCertificateId", certificateId);
            formData.append("IssueDate", (new Date(issueDate)).toUTCString());
            formData.append("ValidFrom", (new Date(validFrom)).toUTCString());
            formData.append("ValidTill", (new Date(validTill)).toUTCString());
            formData.append("CertificateNumber", certificateNumber);
            formData.append("OutwardNumber", outwardNumber);
            formData.append("CertificateFile", certFile);
            formData.append("AddedBy", JSON.parse(window.sessionStorage.getItem('userData')).userId);
            fetch(
                service.ADD_UPDATE_ADVENTURE_CERTIFICATE,
                {
                    method: 'POST',
                    body: formData
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        sessionStorage.setItem('CertificateNumber', null);
                        sessionStorage.setItem('ValidFrom', null);
                        sessionStorage.setItem('ValidTill', null);
                        sessionStorage.setItem('IssueDate', null);
                        sessionStorage.setItem('OutwardNumber', null);
                        sessionStorage.setItem('CertificateId', null);
                        navigate('/adventuretourregistrations');
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                        sessionStorage.setItem('CertificateNumber', null);
                        sessionStorage.setItem('ValidFrom', null);
                        sessionStorage.setItem('ValidTill', null);
                        sessionStorage.setItem('IssueDate', null);
                        sessionStorage.setItem('OutwardNumber', null);
                        sessionStorage.setItem('CertificateId', null);
                        navigate('/adventuretourregistrations');
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        });
        // }, 'image/png')
        setLoading(false);
    }

    const backHandler = () => {
        navigate('/adventuretourregistrations');
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Certificate
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                {/* <Document style={{ width: '100%' }}> */}
                                <div id="divForm" width="100%">
                                    <br />
                                    <br />
                                    <Table id="tblCertificate" style={{ "borderWidth": "5px", 'borderColor': "lightcoral", 'borderStyle': 'solid' }}>
                                        <TableRow>
                                            <TableCell colSpan="2" align="center">
                                                <br />
                                                <br />
                                                <img src={certLogo} alt="Logo" height="200px" width="200px" />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan="2" align="center" style={{ fontSize: '20px', fontWeight: 'bold', color: 'maroon' }}>
                                                पर्यटन संचालनालय
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan="2" align="center" style={{ fontSize: '18px', fontWeight: 'bold', color: 'maroon' }}>
                                                साहसी पर्यटन उपक्रम धोरण - शासन निर्णय क्र..टीडीएस 2020/09/प्र.क्र.490/पर्यटन, दि.24 ऑगस्ट 2021
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan="2" align="center" style={{ fontSize: '20px', fontWeight: 'bold', color: 'maroon' }}>
                                                तात्पुरते नोंदणी प्रमाणपत्र (Provisional Registration Certificate)
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                नोंदणी क्रमांक (Registration No.)
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                : {certificateNumber}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                विभागीय कार्यालय (Divisional Office)
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                : {data.region}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                नोंदणी धारकाचे नाव श्री / श्रीमती/ संस्था (Applicant`s Name Shri/Smt/Organization)
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                : {data.applicantName}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                कंपनीचे नाव
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                : {data.name}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                नोंदणी धारकाचा पत्ता (Applicant`s Office Address)
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                : {data.applicantAddress}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                नोंदणी धारकाचा फोन नं. (Applicant`s Phone No.)
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                : {data.mobileNo}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                साहसी पर्यटन प्रकार (Type of Adventure Tourism Activity)
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                : {data.activityCategory}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                साहसी पर्यटन उपक्रमाचे नाव/नावे (Type of Adventure Tourism Activity)

                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                : {
                                                    advDoc.map(row => (
                                                        row.documentType !== 'Common' ? `${row.documentType},` : ''
                                                    ))
                                                }

                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                Location
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                : {data.activityLocationAddress}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                तात्पुरते नोंदणी प्रमाणपत्राचा कालावधी (Provisional registration valid for the period of)
                                            </TableCell>
                                            <TableCell style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                : {moment(validFrom).format('DD-MM-YYYY')} To {moment(validTill).format('DD-MM-YYYY')}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan="2" style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                Issue Date: {moment(issueDate).format('DD-MM-YYYY')}
                                            </TableCell>
                                        </TableRow>
                                        {/* <TableRow>
                                            <TableCell id="tcSignature" colSpan="2" align="right" style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                <img src={signatureImage} alt="sign" width="180px" />
                                            </TableCell>
                                        </TableRow> */}
                                        <TableRow>
                                            <TableCell colSpan="2" align="right" style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                <br />                                                
                                                {JSON.parse(sessionStorage.getItem('userData')).userName}
                                                <br />
                                                <br />                                               
                                            </TableCell>
                                        </TableRow>
                                        {/* <TableRow>
                                            <TableCell colSpan="2" align="right" style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                <img src={stampImage} alt="stamp" width="180px" />
                                            </TableCell>
                                        </TableRow> */}
                                        <TableRow>
                                            <TableCell colSpan="2" style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                *शासन निर्णय क्र. टी डीएस 2020/09/प्र.क्र.490/पर्यटन, दि.24 ऑगस्ट 2021 व साहसी पर्यटन उपक्रमासंदर्भातील मार्गदर्शक सुचनांचे (www.maharashtratourism.gov.in) काटेकोर पालन करण्याच्या अधिन राहुन तात्पुरते प्रमाणपत्र देण्यात येत आहे.
                                            </TableCell>
                                            <br />
                                            <br />
                                        </TableRow>
                                    </Table>
                                    {/* <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <TableRow>
                                            <TableCell colSpan="2" align="center" style={{ fontSize: '20px', fontWeight: 'bold', color: 'maroon' }}>
                                                अटी व शर्ती
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan="2">
                                                <Table>
                                                    <TableRow>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            1
                                                        </TableCell>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            सहभागींना साहसी उपक्रमाविषयी संपूर्ण माहिती, साहसी उपक्रमाचा तपशील आणि त्यातील धोके इत्यादींची माहिती देणे बंधनकारक आहे.
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            2
                                                        </TableCell>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            साहसी पर्यटनात सहभागी होणाऱ्या सर्व उमेदवारामध्ये 18 वर्षाखालील बालकांचा समावेश असल्यास त्यांच्या पालकांची ना-हरकत प्रमाणपत्र /लेखी परवानगी घेणे बंधनकारक असेल.
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            3
                                                        </TableCell>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            सहभागींची नोंदणी प्रक्रिया पूर्ण करणे आणि संस्थेच्या निर्णयानुसार जोखीम हमीपत्र (Risk Undertaking), वैद्यकीय प्रमाणपत्र इत्यादींसारख्या अनिवार्य बाबींची पूर्तता करणे बंधनकारक असेल.
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            4
                                                        </TableCell>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            पोलीस महसुल विभाग, जिल्हा परिषद जलसंपदा किंवा स्थानिक स्वराज्य संस्था इ. (लागू असलेल्या संस्था) सर्व संबंधित शासकीय यंत्रणांची परवानगीपणे बंधनकारक आहे.
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            5
                                                        </TableCell>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            आपण आयोजित करत असलेल्या उपक्रमादरम्यान DOT चा लोगो वापरणे आवश्यक आहे.
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            6
                                                        </TableCell>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            साहसी पर्यटनासाठी आवश्यक असणारी साधनसामुग्री, उपकरणे, यंत्रे इत्यादी  सुस्थितीत ठेवून त्यांची  योग्यरित्या देखभाल ठेवण्यास व साहसी पर्यटन करताना तज्ञ मार्गदर्शक हजर ठेवणे बंधनकारक राहिल. आवश्यक साधनसामग्री; तांत्रिक साधनसामग्री, प्रथमोपचार संच आणि आपत्कालीन परिस्थितीत वापरण्याचा संच (संपर्क साधनांसह) ईएन किंवा यूआयएए प्रमाणित असणे बंधनकारक आहे
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            7
                                                        </TableCell>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            अपूर्ण / अर्धवट / गरजेपेक्षा कमी दर्जाच्या सुरक्षा उपाययोजना / साहित्य इत्यादी आढळल्यास संबंधित आयोजक / यांचे प्रमाणपत्र रद्द करण्यात येईल.
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            8
                                                        </TableCell>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            संस्थांनी साहसी उपक्रमावर आधारित तांत्रिक साधनसामग्रीचा आपत्कालीन संच तयार करणे आवश्यक आहे.
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            9
                                                        </TableCell>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            सहभागी व्यक्तींना वगळण्याचे निकष -
                                                            <br />
                                                            अंमली पदार्थ/ मद्य सेवन केलेली व्यक्ती
                                                            <br />
                                                            सुरक्षित पद्धतींचा अवलंब करण्यास नकार देणारी व्यक्ती
                                                            <br />
                                                            धोका उत्पन्न होऊ शकतो अशी वैद्यकीय समस्या असलेली व्यक्ती
                                                            <br />
                                                            सुरक्षा साधने घालण्यास नकार देणारी व्यक्ती
                                                            <br />
                                                            गर्भवती महिला
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            10
                                                        </TableCell>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            उपक्रमादरम्यान गटप्रमुखाकडे आवश्यक असलेली कागदपत्रे -
                                                            <br />
                                                            कायदेशीर कागदपत्रे (नोंदणीपत्र, परवाना इ.)
                                                            <br />
                                                            सहभागी व  गटप्रमुख वैयक्तिक माहिती
                                                            <br />
                                                            सहभागींकडून घेतलेली हमीपत्रे
                                                            <br />
                                                            जिथे उपक्रम आयोजित केला आहे त्या ठिकाणचे  जोखीम मूल्यांकन (risk analysis) आणि संभाव्य धोके कमी करण्यासाठीचे नियोजन
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            11
                                                        </TableCell>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            साहसी उपक्रमादरम्यान -
                                                            <br />
                                                            परिचय; सहभागी आणि गटप्रमुख ओळख करणे आवश्यक आहे
                                                            <br />
                                                            सहभागी धारकांना माहिती देणे आवश्यक आहे
                                                            <br />
                                                            सुरक्षा मार्गदर्शक सूचनांनुसारच साहसी उपक्रम पार पाडणे
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            12
                                                        </TableCell>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            साहसी उपक्रम संपल्यानंतर -
                                                            <br />
                                                            साधनसामग्री जमा करणे, आवश्यक असल्यास दुरुस्ती करून घेणे.
                                                            <br />
                                                            मुख्य गटप्रमुख सादर केलेल्या सर्व अहवालांचा आढावा घेणे.
                                                            <br />
                                                            भविष्यात असाच उपक्रम पुन्हा करायचा झाल्यास त्यात काय सुधारणा करता येईल याचा विचार करण्यासाठी जोखीम व्यवस्थापन गट किंवा संस्थेच्या व्यवस्थापनानेने त्यांचे सादरीकरण, अभिप्राय आणि गंभीर घटनेसंबंधी अहवाल (अशी घटना घडली असल्यास) अभ्यासावा.
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            13
                                                        </TableCell>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            साहसी पर्यटन करताना सहभागींच्या जिवीतास कोणत्याही प्रकारे अपाय झाल्यास अथवा कोणताही अपघात घडल्यास त्याची सर्वस्वी जबाबदारी आपणावर राहील.
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            14
                                                        </TableCell>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            सदरहू साहसी पर्यटन उपक्रमचा वापर कोणत्याही देश विघातक कामासाठी होणार नाही, याची संपूर्ण दक्षता घेण्याची जबाबदारी आपणावर राहील.
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            15
                                                        </TableCell>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            साहसी पर्यटन उपक्रमाच्या ठिकाणी शासनाच्या किंवा एखादा जनहितार्थ प्रकल्प राबवायचा झाल्यास ही परवानगी रद्द करण्यासंदर्भातील सर्व अधिकार निम्नस्वाक्षरीकार यांच्या अखत्यारित राहतील याची नोंद घ्यावी.
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            16
                                                        </TableCell>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            सदरहू साहसी पर्यटन उपक्रमामुळे परिसर अस्वच्छ, तसेच पर्यावरणाची हानी होणार नाही, याची दक्षता घेणे बंधनकारक राहील. साहसी पर्यटन उपक्रम राबविताना ‘Leave No Trace’ तत्वांचे पालन करावे.
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            17
                                                        </TableCell>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            हवा आधारित साहसी पर्यटन केंद्राच्या जागेपासून किमान 500 मीटर अंतरावर कोणत्याही प्रकारचे अडथळे नसावेत.
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            18
                                                        </TableCell>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            साहसी पर्यटन उपक्रम धोरणतील तरतुदी लागू असणाऱ्या सर्व साहसी पर्यटन उपक्रम आयोजकांनी तात्परते नोंदणी प्रमाणपत्र सहायक संचालक, पर्यटन संचालनालय, विभागीय पर्यटन कार्यालय, पुणे यांच्याकडून प्राप्त करणे बंधनकारक आहे.
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            19
                                                        </TableCell>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            प्रत्येक नोंदणी धारकाला दरवर्षी 30 जूनपर्यंत मागील आर्थिक वर्षात (1 एप्रिल ते 31 मार्च) व दर 3 महिन्यानंतर या कार्यालयास आयोजित साहसी पर्यटन उपक्रमाचा अहवाल विहित नमुन्यात सादर करणे बंधनकारक आहे. (सोबत नमुना जोडला आहे.)
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            20
                                                        </TableCell>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            सदर उपक्रमासंबंधी तक्रार आढळल्यास आपले तात्पुरते नोंदणी प्रमाणपत्र या कार्यालयामार्फत रद्द करण्यात येवून कारवाई करण्यात येईल, याची नोंद घेण्यात यावी.
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            21
                                                        </TableCell>
                                                        <TableCell style={{ fontSize: '18px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            शासन निर्णय क्रमांक दिनांक 24 ऑगस्ट 2021 टिडीएस 2020/09 प्र.क्र.490/ पर्यटन अन्वये दिलेल्या अटी व सुरक्षाविषयक सूचना या पर्यटन संचालानलयामार्फत वेळोवेळी देणेत येतील त्या मार्गदर्शक सूचना लागू राहतील.
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell colSpan="2" align="right" style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                            <br />
                                                            <br />
                                                            {JSON.parse(sessionStorage.getItem('userData')).userName}
                                                            <br />
                                                            <br />
                                                            <br />
                                                            <br />
                                                        </TableCell>
                                                    </TableRow>
                                                </Table>
                                            </TableCell>
                                        </TableRow>                                       
                                    </Table> */}
                                </div>
                                <Table>
                                    <TableRow>
                                        <TableCell>
                                            {loading ? <Oval
                                                heigth="20"
                                                width="20"
                                                color='grey'
                                                ariaLabel='loading'
                                            /> :
                                                <MDButton color="info" onClick={submitHandler}>
                                                    Save
                                                </MDButton>
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <MDButton color="secondary" onClick={backHandler}>
                                                Back
                                            </MDButton>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".error"]}</div>
                                {/* </Document> */}
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default GenerateLandCertificate;