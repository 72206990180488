/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
// import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
// import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 PRO React routes
import routes from "routes";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setMiniSidenav } from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import AddEditRegion from "layouts/masters/region/Transaction";
import AddEditDistrict from "layouts/masters/district/Transaction";
import AddEditSiteVisitCriteria from "layouts/masters/sitevisitcriteria/Transaction";
import AddEditUser from "layouts/masters/user/Transaction";
import ViewAgroTourRegistrationDetails from "layouts/agrotour/viewagrotourregistrationdetails";
import ViewAdventureTourRegistrationDetails from "layouts/adventuretour/viewadventureregistrationdetails";
import ViewCaravanRegistrationDetails from "layouts/caravan/viewcaravanregistrationdetails";
import ViewAccomodationRegistrationDetails from "layouts/accomodation/viewaccomodationregistrationdetails";
import AgroTourSiteVisit from "layouts/agrotour/agrotoursitevisitcriteria";
import AdventureTourSiteVisit from "layouts/adventuretour/adventuretoursitevisitcriteria";
import CaravanSiteVisit from "layouts/caravan/caravansitevisitcriteria";
import AccomodationSiteVisit from "layouts/accomodation/accomodationsitevisitcriteria";
import AccomodationCertificate from "layouts/accomodation/accomodationcertificate";
import AdventureCertificate from "layouts/adventuretour/adventurecertificate";
import AgroCertificate from "layouts/agrotour/agrocertificate";
import CaravanCertificate from "layouts/caravan/caravancertificate";
import GenerateLandCertificate from "layouts/adventuretour/generatelandcertificate";
import GenerateAgroCertificate from "layouts/agrotour/generateagrocertificate";
import GenerateCaravanCertificate from "layouts/caravan/generatecaravancertificate";
import GenerateAccomodationCertificate from "layouts/accomodation/generateaccomodationcertificate";
import ViewAdventureApplication from "layouts/adventureapplication/viewadventureapplication";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  // Check authentication
  const userData = sessionStorage.getItem('userData');

  useEffect(() => {
    if (userData == null) {
      navigate('/authentication/sign-in/basic');
    }
  }, []);
  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  // const configsButton = (
  //   <MDBox
  //     display="flex"
  //     justifyContent="center"
  //     alignItems="center"
  //     width="3.25rem"
  //     height="3.25rem"
  //     bgColor="white"
  //     shadow="sm"
  //     borderRadius="50%"
  //     position="fixed"
  //     right="2rem"
  //     bottom="2rem"
  //     zIndex={99}
  //     color="dark"
  //     sx={{ cursor: "pointer" }}
  //     onClick={handleConfiguratorOpen}
  //   >
  //     <Icon fontSize="small" color="inherit">
  //       settings
  //     </Icon>
  //   </MDBox>
  // );

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="TDMS"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {/* {configsButton} */}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/dashboards/analytics" />} />
          <Route path="/addeditregion/:id" element={<AddEditRegion />} />
          <Route path="/addeditdistrict/:id" element={<AddEditDistrict />} />
          <Route path="/addeditsitevisitcriteria/:id" element={<AddEditSiteVisitCriteria />} />
          <Route path="/addedituser/:id" element={<AddEditUser />} />
          <Route path="/viewagrotourregistrationdetails/:id" element={<ViewAgroTourRegistrationDetails />} />
          <Route path="/viewadventuretourregistrationdetails/:id" element={<ViewAdventureTourRegistrationDetails />} />
          <Route path="/viewcaravanregistrationdetails/:id" element={<ViewCaravanRegistrationDetails />} />
          <Route path="/viewaccomodationregistrationdetails/:id" element={<ViewAccomodationRegistrationDetails />} />
          <Route path="/agrotoursitevisit/:id" element={<AgroTourSiteVisit />} />
          <Route path="/adventuretoursitevisit/:id" element={<AdventureTourSiteVisit />} />
          <Route path="/caravansitevisit/:id" element={<CaravanSiteVisit />} />
          <Route path="/accomodationsitevisit/:id" element={<AccomodationSiteVisit />} />
          <Route path="/accomodationcertificate/:id" element={<AccomodationCertificate />} />
          <Route path="/adventurecertificate/:id" element={<AdventureCertificate />} />
          <Route path="/agrocertificate/:id" element={<AgroCertificate />} />
          <Route path="/caravancertificate/:id" element={<CaravanCertificate />} />
          <Route path="/generatelandcertificate/:id" element={<GenerateLandCertificate />} />
          <Route path="/generateagrocertificate/:id" element={<GenerateAgroCertificate />} />
          <Route path="/generatecaravancertificate/:id" element={<GenerateCaravanCertificate />} />
          <Route path="/generateaccomodationcertificate/:id" element={<GenerateAccomodationCertificate />} />
          <Route path="/viewadventureapplication/:id" element={<ViewAdventureApplication />} />
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="TDMS"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {/* {configsButton} */}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/dashboards/analytics" />} />
        <Route path="/addeditregion/:id" element={<AddEditRegion />} />
        <Route path="/addeditdistrict/:id" element={<AddEditDistrict />} />
        <Route path="/addeditsitevisitcriteria/:id" element={<AddEditSiteVisitCriteria />} />
        <Route path="/addedituser/:id" element={<AddEditUser />} />
        <Route path="/viewagrotourregistrationdetails/:id" element={<ViewAgroTourRegistrationDetails />} />
        <Route path="/viewadventuretourregistrationdetails/:id" element={<ViewAdventureTourRegistrationDetails />} />
        <Route path="/viewcaravanregistrationdetails/:id" element={<ViewCaravanRegistrationDetails />} />
        <Route path="/viewaccomodationregistrationdetails/:id" element={<ViewAccomodationRegistrationDetails />} />
        <Route path="/agrotoursitevisit/:id" element={<AgroTourSiteVisit />} />
        <Route path="/adventuretoursitevisit/:id" element={<AdventureTourSiteVisit />} />
        <Route path="/caravansitevisit/:id" element={<CaravanSiteVisit />} />
        <Route path="/accomodationsitevisit/:id" element={<AccomodationSiteVisit />} />
        <Route path="/accomodationcertificate/:id" element={<AccomodationCertificate />} />
        <Route path="/adventurecertificate/:id" element={<AdventureCertificate />} />
        <Route path="/agrocertificate/:id" element={<AgroCertificate />} />
        <Route path="/caravancertificate/:id" element={<CaravanCertificate />} />
        <Route path="/generatelandcertificate/:id" element={<GenerateLandCertificate />} />
        <Route path="/generateagrocertificate/:id" element={<GenerateAgroCertificate />} />
        <Route path="/generatecaravancertificate/:id" element={<GenerateCaravanCertificate />} />
        <Route path="/generateaccomodationcertificate/:id" element={<GenerateAccomodationCertificate />} />
        <Route path="/viewadventureapplication/:id" element={<ViewAdventureApplication />} />
      </Routes>
    </ThemeProvider>
  );
}
