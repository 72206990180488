/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
// import Icon from "@mui/material/Icon";
// Authentication layout components
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as moment from 'moment';
import { Oval } from 'react-loader-spinner';
import { jsPDF } from 'jspdf';
import html2canvas from "html2canvas";
import * as service from '../../services/service';

function ViewAccomodationRegistrationDetails() {
    const [state, setState] = useState(
        {
            remarks: ''
        });
    const [data, setData] = useState([{}]);
    const [documentData, setDocuemntData] = useState([{}]);
    const [loading, setLoading] = useState(false);
    const userRl = JSON.parse(sessionStorage.getItem('userData')).userRole;

    const navigate = useNavigate();
    const params = useParams();

    const getRegistrationById = () => {
        setLoading(true);
        // const errs = {};        
        if (params.id > 0) {
            fetch(
                service.GET_ACCOMODATION_REGISTRATION_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        AccomodationId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        setData(result);
                        setState({ ...state, remarks: result.remarks });
                    }
                    else {
                        // eslint-disable-next-line
                        alert(result.message.message);
                    }
                });
        }
        setLoading(false);
    };

    const getDocumentsById = () => {
        setLoading(true);
        // const errs = {};        
        if (params.id > 0) {
            fetch(
                service.GET_ACCOMODATION_DOCUMENTS_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        AccomodationId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result[0].message.messageType === 'Success') {
                        setDocuemntData(result);
                    }
                    else {
                        // eslint-disable-next-line
                        alert(result.message.message);
                    }
                });
        }
        setLoading(false);
    };
    useEffect(() => {
        setLoading(true);
        getRegistrationById();
        getDocumentsById();
        setLoading(false);
    }, []);

    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const backHandler = () => {
        navigate('/accomodationregistrations');
    };

    const handleDownload = (fileName, filePath) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("FileName", fileName);
        formData.append("FilePath", filePath);
        fetch(
            service.DOWNLOAD_FILE,
            {
                method: 'POST',
                body: formData
            })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    fileName,
                );
                // Append to html link element page
                document.body.appendChild(link);
                // Start download
                link.click();
                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });

        setLoading(false);
    };

    const submitHandler = (regStatus) => {
        // e.preventDefault();
        const errs = {};
        setLoading(true);
        // eslint-disable-next-line
        if (window.confirm("Are you sure...?")) {
            fetch(
                service.UPDATE_ACCOMODATION_REGISTRATION_VERIFICATION,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        AccomodationId: params.id,
                        Status: userRl === 'DoT' || userRl === 'DTO' ? regStatus : '',
                        AssistantDirectorStatus: userRl === 'Assistant Director' ? regStatus : '',
                        JointDirectorStatus: userRl === 'Joint Director' ? regStatus : '',
                        DirectorStatus: userRl === 'Director' ? regStatus : '',
                        Remarks: state.remarks
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        navigate('/accomodationregistrations');
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        // setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }

    const verifyDocumentHandler = (id, docStatus) => {
        const errs = {};
        setLoading(true);
        // eslint-disable-next-line
        if (window.confirm("Are you sure...?")) {
            fetch(
                service.UPDATE_ACCOMODATION_DOCUMENT_VERIFICATION,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        AccomodationDocumentId: id,
                        Status: docStatus,
                        VerifiedBy: JSON.parse(window.sessionStorage.getItem('userData')).userId,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        window.location.reload();
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        // setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }
    const printDocument = () => {
        setTimeout(() => {
            setTimeout(() => {
                setLoading(true);
            }, 100);
            const divToPrint = document.querySelector('#divForm');
            html2canvas(divToPrint)
                .then((canvas) => {
                    const imgData = canvas.toDataURL('image/png');
                    const imgWidth = 190;
                    const pageHeight = 290;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    let heightLeft = imgHeight;
                    // eslint-disable-next-line new-cap
                    const doc = new jsPDF('pt', 'mm');
                    let position = 0;
                    doc.addImage(imgData, 'PNG', 10, 0, imgWidth, imgHeight + 25);
                    heightLeft -= pageHeight;
                    while (heightLeft >= 0) {
                        position = heightLeft - imgHeight;
                        doc.addPage();
                        doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight + 25);
                        heightLeft -= pageHeight;
                    }
                    doc.save('ApplicationForm.pdf');
                    setLoading(false);
                });
        }, 1000);
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div style={{ width: '100%' }}>
                <Card>
                    <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                        mx={2}
                        mt={-3}
                        p={2}
                        mb={1}
                        textAlign="center"
                    >
                        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                            Accomodation Registration For Industrial Status Details
                        </MDTypography>
                    </MDBox>
                    {loading ? <Oval
                        heigth="100"
                        width="100"
                        color='grey'
                        ariaLabel='loading'
                    /> :
                        <MDBox pt={4} pb={3} px={3}>
                            <MDBox component="form" role="form">
                                {/* <div style={{ fontSize: '12px', color: 'red' }}>{errors}</div> */}
                                <Card style={{ alignItems: 'center' }}>
                                    <MDBox pt={4} pb={3} px={3} style={{ width: '100%' }}>
                                        <div id="divForm" width="100%">
                                            <TableContainer component={Paper} style={{ width: '100%', alignSelf: 'center' }}>
                                                <Table id="tblReceipt" style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell colSpan={2}>
                                                                <Table style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Email Id :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.emailId}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Mobile No. :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.mobileNo}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Registration Date :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {moment(data.addedDate).format("DD/MM/YYYY")}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Name of Hotel / Resort :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.hotelName}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Name of Authorized Person :
                                                                        </TableCell>
                                                                        <TableCell colSpan="3" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.authorizedPerson}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Full Address :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.address}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Pin Code :
                                                                        </TableCell>
                                                                        <TableCell colSpan="3" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.pinCode}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Region :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.region}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            District :
                                                                        </TableCell>
                                                                        <TableCell colSpan="3" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.district}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Hotel Address :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.hotelAddress}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Emergency Contact No. :
                                                                        </TableCell>
                                                                        <TableCell colSpan="3" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.emergencyContactNo}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Applicant Type :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.applicantType}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Total Area in Sq.Mt. :
                                                                        </TableCell>
                                                                        <TableCell colSpan="3" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.areaSqMt}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell width="20%" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Total Number of Rooms :
                                                                        </TableCell>
                                                                        <TableCell width="20%" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.roomNo}
                                                                        </TableCell>
                                                                        <TableCell width="15%" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Total Number of Employees :
                                                                        </TableCell>
                                                                        <TableCell width="20%" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.employeeNo}
                                                                        </TableCell>
                                                                        <TableCell width="15%" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Commencement Date :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {moment(data.commencementDate).format("DD/MM/YYYY")}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell colSpan="6" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            General Requirements :
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Minimum 6 lettable rooms, all rooms with outside windows / ventilation :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.rooms ? "Yes" : "No"}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Minimum Rooms size should be as follows (Room size excludes bathroom) All rooms must have attached bathroom mandatorily - Single - 80 sq. ft. and Double - 120 sq. fL :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.roomSize ? "Yes" : "No"}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Minimum Bathroom size should be 30 sq. ft. :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.bathroomSize ? "Yes" : "No"}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Bathroom Sanitary Fixtures Toilets must be well ventilated. Each western WC toilet should have a seat with lid and toilet paper. Post toilet hygiene facilities - toilet paper, soap, sanitary bin, 24-hour running water. :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.sanitaryFixtures ? "Yes" : "No"}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Full time operation 7 days a week :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.fullTimeOperation ? "Yes" : "No"}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            24 hrs. elevators for buildings higher than ground plus four floors or as per the prevailing local building norms applicable :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.elevator ? "Yes" : "No"}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            24x7 availability of electricity :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.electricity ? "Yes" : "No"}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Emergency lights available in the public areas :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.emergencyLights ? "Yes" : "No"}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Hotel Entrances and all common areas are controlled by CCTV Cameras 24 hours a day :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.cCTV ? "Yes" : "No"}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Differently Abled Guest Friendly Access at the entrance :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.guestAccess ? "Yes" : "No"}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Security Guards available 24 hours a day :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.securityGuards ? "Yes" : "No"}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell colSpan="6" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Bathroom
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Rooms with attached bathrooms :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.attachedBathroom ? "Yes" : "No"}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Availability of Hot and Cold running water :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.runningWater ? "Yes" : "No"}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Water saving taps and showers :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.tapsShowers ? "Yes" : "No"}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell colSpan="6" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Public Area
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Lounge or seating area in the lobby :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.lounge ? "Yes" : "No"}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Reception facility :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.reception ? "Yes" : "No"}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Public restrooms with a wash basin, a mirror, a sanitary bin with lid in unisex toilet :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.restRoom ? "Yes" : "No"}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell colSpan="6" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Room And Facilities for the Differently Abled Guests
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Atleast one room for the differently able guest :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.guestRoom ? "Yes" : "No"}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell colSpan="6" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Kitchen / Food Production Area
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            FSSAI registration / Licensed Kitchen :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.kitchen ? "Yes" : "No"}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell colSpan="6" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Hotel Staff And Related Facilities
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Uniforms for front house staff :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.uniform ? "Yes" : "No"}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell colSpan="6" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Code of Conduct for Safe And Honorable Tourism
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Display of pledge :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.pledge ? "Yes" : "No"}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Maintenance of Complaint book and Suggestion Book :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.complaintBook ? "Yes" : "No"}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Appointment of Nodal officers and display of information of Nodal officer at reception :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.nodalOfficer ? "Yes" : "No"}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell colSpan="6" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Guest Services
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Availability of Doctor-on-call service and name address and telephone number of doctors with front desk :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.doctorOnCall ? "Yes" : "No"}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell colSpan="6" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Safety And Security
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Police Verification for employees :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.policeVerification ? "Yes" : "No"}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Conducting regular fire fighting drills and adherence to norms of the Fire department :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.fireDrills ? "Yes" : "No"}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            First aid kit at the front desk :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.firstAidKit ? "Yes" : "No"}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell colSpan="6" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Additional Features
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Suite (2 rooms or 2 room- bays having a bedroom and separate sitting area, having one bathroom and one powder room) :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.suite ? "Yes" : "No"}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            F and B Outlet :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.fBOutlet ? "Yes" : "No"}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Iron and Iron Board facility :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.ironBoard ? "Yes" : "No"}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Paid transportation on call :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.paidTransportation ? "Yes" : "No"}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Business Center :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.businessCenter ? "Yes" : "No"}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Conference Facilities :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.conference ? "Yes" : "No"}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Sewage Treatment Plant :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.sewageTreatment ? "Yes" : "No"}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Rainwater Harvesting :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.rainwaterHarvesting ? "Yes" : "No"}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Other :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.other ? "Yes" : "No"}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Electricity Company Name And Address  :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.electricityCompanyDetails}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Property Tax Dept Name And Address :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.propertyTaxDeptDetails}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Water Bill Dept Name And Address
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.waterBillDeptDetails}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            MSEB Consumer Number :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.msebConsumerNo}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Star Category :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.starCategory}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            DoT Status
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.status}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Assistant Director Status
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.assistantDirectorStatus}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Joint Director Status
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.jointDirectorStatus}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Director Status
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.directorStatus}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </Table>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                                <Table>
                                                    <TableRow>
                                                        <TableCell colSpan="6" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            Documents
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            Document Name
                                                        </TableCell>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            File Name
                                                        </TableCell>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            Status
                                                        </TableCell>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            Verification Date
                                                        </TableCell>
                                                        {userRl === "DoT" ?
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Approve
                                                            </TableCell>
                                                            : null}
                                                        {userRl === "DoT" ?
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Reject
                                                            </TableCell>
                                                            : null}
                                                    </TableRow>
                                                    <TableBody>
                                                        {documentData.map((row) => (
                                                            <TableRow
                                                                key={row.accomodationDocumentId}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                    <MDButton onClick={() => handleDownload(row.fileName, row.filePath)}>{row.documentName}</MDButton>
                                                                </TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.fileName}</TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.status}</TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.verificationDate ? moment(row.verificationDate).format("DD/MM/YYYY") : ''}</TableCell>
                                                                {userRl === "DoT" ?
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        <MDButton onClick={() => verifyDocumentHandler(row.accomodationDocumentId, "Approved")}>Approved</MDButton>
                                                                    </TableCell>
                                                                    : null}
                                                                {userRl === "DoT" ?
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        <MDButton onClick={() => verifyDocumentHandler(row.accomodationDocumentId, "Rejected")}>Rejected</MDButton>
                                                                    </TableCell>
                                                                    : null}
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                    <TableRow>
                                                        <TableCell colSpan="6" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            <MDInput label="Remarks" fullWidth value={state.reamrks}
                                                                name="remarks"
                                                                onChange={changeHandler}
                                                                id="remarks"
                                                                multiline
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </MDBox>
                                    <MDBox mt={4} mb={1} textAlign="center">
                                        <Table>
                                            <TableRow>
                                                {userRl === "DoT" || userRl === "Assistant Director" || userRl === "Joint Director" || userRl === "Director" ?
                                                    <TableCell>
                                                        <MDButton color="info" onClick={() => submitHandler("Approved")}
                                                            disabled={data.documentStatus === "Rejected"
                                                                || (data.status === "Approved" && userRl === "DoT")
                                                                || (data.assistantDirectorStatus === "Approved" && userRl === "Assistant Director")
                                                                || (data.jointDirectorStatus === "Approved" && userRl === "Joint Director")
                                                                || (data.directorStatus === "Approved" && userRl === "Director")
                                                            }>
                                                            Approved
                                                        </MDButton>
                                                    </TableCell>
                                                    : null}
                                                {userRl === "DoT" || userRl === "Assistant Director" || userRl === "Joint Director" || userRl === "Director" ?
                                                    <TableCell>
                                                        <MDButton color="info" onClick={() => submitHandler("Rejected")}
                                                            disabled={(data.status === "Rejected" && userRl === "DoT")
                                                                || (data.assistantDirectorStatus === "Rejected" && userRl === "Assistant Director")
                                                                || (data.jointDirectorStatus === "Rejected" && userRl === "Joint Director")
                                                                || (data.directorStatus === "Rejected" && userRl === "Director")
                                                            }>
                                                            Rejected
                                                        </MDButton>
                                                    </TableCell>
                                                    : null}
                                                <TableCell>
                                                    <MDButton color="info" onClick={() => printDocument()}>
                                                        PRINT
                                                    </MDButton>
                                                </TableCell>
                                                <TableCell>
                                                    <MDButton color="secondary" onClick={backHandler}>
                                                        Back
                                                    </MDButton>
                                                </TableCell>
                                            </TableRow>
                                        </Table>
                                    </MDBox>
                                </Card>
                            </MDBox>
                        </MDBox>

                    }
                </Card>
            </div>
        </DashboardLayout>
    );
}

export default ViewAccomodationRegistrationDetails;
