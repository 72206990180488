/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// import MDInput from "components/MDInput";
// import Icon from "@mui/material/Icon";
// Authentication layout components
import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as moment from 'moment';
import { Oval } from 'react-loader-spinner';
import { jsPDF } from 'jspdf';
import html2canvas from "html2canvas";
import * as service from '../../services/service';

function ViewAdventureApplication() {
    const [state, setState] = useState(
        {
            remarks: ''
        });
    const [data, setData] = useState([{}]);
    const [loading, setLoading] = useState(false);
    // const userRl = JSON.parse(sessionStorage.getItem('userData')).userRole;
    // const reg = JSON.parse(sessionStorage.getItem('userData')).region;

    const navigate = useNavigate();
    const params = useParams();

    const getRegistrationById = () => {
        setLoading(true);
        // const errs = {};        
        if (params.id > 0) {
            fetch(
                service.GET_ADVENTURE_APPLICATION_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        AdventureApplicationId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        setData(result);
                        setState({ ...state, remarks: result.remarks });
                    }
                    else {
                        // eslint-disable-next-line
                        alert(result.message.message);
                    }
                });
        }
        setLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        getRegistrationById();
        setLoading(false);
    }, []);

    // const changeHandler = (event) => {
    //     setState({ ...state, [event.target.name]: event.target.value });
    // };

    const backHandler = () => {
        navigate('/adventureapplications');
    };

    const handleDownload = (fileName, filePath) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("FileName", fileName);
        formData.append("FilePath", filePath);
        fetch(
            service.DOWNLOAD_FILE,
            {
                method: 'POST',
                body: formData
            })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    fileName,
                );
                // Append to html link element page
                document.body.appendChild(link);
                // Start download
                link.click();
                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });

        setLoading(false);
    };

    const submitHandler = (regStatus) => {
        // e.preventDefault();
        const errs = {};
        setLoading(true);
        // eslint-disable-next-line
        if (window.confirm("Are you sure...?")) {
            fetch(
                service.UPDATE_ADVENTURE_APPLICATION_VERIFICATION,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        AdventureApplicationId: params.id,
                        Status: regStatus,
                        Remarks: state.remarks
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        navigate('/adventureapplications');
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        // setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }


    const printDocument = () => {
        setTimeout(() => {
            setTimeout(() => {
                setLoading(true);
            }, 100);
            const divToPrint = document.querySelector('#divForm');
            html2canvas(divToPrint)
                .then((canvas) => {
                    const imgData = canvas.toDataURL('image/png');
                    const imgWidth = 190;
                    const pageHeight = 290;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    let heightLeft = imgHeight;
                    // eslint-disable-next-line new-cap
                    const doc = new jsPDF('pt', 'mm');
                    let position = 0;
                    doc.addImage(imgData, 'PNG', 10, 0, imgWidth, imgHeight + 25);
                    heightLeft -= pageHeight;
                    while (heightLeft >= 0) {
                        position = heightLeft - imgHeight;
                        doc.addPage();
                        doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight + 25);
                        heightLeft -= pageHeight;
                    }
                    doc.save('ApplicationForm.pdf');
                    setLoading(false);
                });
        }, 1000);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div style={{ width: '100%' }}>
                <Card>
                    <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                        mx={2}
                        mt={-3}
                        p={2}
                        mb={1}
                        textAlign="center"
                    >
                        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                            Adventure Application Details
                        </MDTypography>
                    </MDBox>
                    {loading ? <Oval
                        heigth="100"
                        width="100"
                        color='grey'
                        ariaLabel='loading'
                    /> :
                        <MDBox pt={4} pb={3} px={3}>
                            <MDBox component="form" role="form">
                                {/* <div style={{ fontSize: '12px', color: 'red' }}>{errors}</div> */}
                                <Card style={{ alignItems: 'center' }}>
                                    <MDBox pt={4} pb={3} px={3} style={{ width: '100%' }}>
                                        <div id="divForm" width="90%">
                                            <TableContainer component={Paper} style={{ width: '100%', alignSelf: 'center' }}>
                                                <Table id="tblReceipt" style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                    <TableRow>
                                                        <TableCell width="25%" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            Email Id :
                                                        </TableCell>
                                                        <TableCell width="16%" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            {data.emailId}
                                                        </TableCell>
                                                        <TableCell width="16%" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            Mobile No. :
                                                        </TableCell>
                                                        <TableCell width="16%" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            {data.mobileNo}
                                                        </TableCell>
                                                        <TableCell width="16%" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            Registration Date :
                                                        </TableCell>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            {moment(data.addedDate).format("DD/MM/YYYY")}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            Applicant Name :
                                                        </TableCell>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            {data.applicantName}
                                                        </TableCell>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            Name of the Individual/Company/Partnership Firm/Trust etc. to be registered :
                                                        </TableCell>
                                                        <TableCell colSpan="3" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            {data.name}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            Applicant Type :
                                                        </TableCell>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            {data.applicantType}
                                                        </TableCell>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            Applicant Address :
                                                        </TableCell>
                                                        <TableCell colSpan="3" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            {data.applicantAddress}
                                                        </TableCell>
                                                    </TableRow>                                                    
                                                    <TableRow>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            Region :
                                                        </TableCell>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            {data.region}
                                                        </TableCell>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            District :
                                                        </TableCell>
                                                        <TableCell colSpan="3" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            {data.district}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            Whats App Number :
                                                        </TableCell>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            {data.whatsAppNo}
                                                        </TableCell>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            Adventure Activity Category :
                                                        </TableCell>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            {data.activityCategory}
                                                        </TableCell>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            Adventure Activity Name :
                                                        </TableCell>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            {data.activityName}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            Activity Location Address :
                                                        </TableCell>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            {data.activityLocationAddress}
                                                        </TableCell>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            PAN File :
                                                        </TableCell>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            <MDButton onClick={() => handleDownload(data.panFileName, data.panFilePath)}>{data.panFileName}</MDButton>
                                                        </TableCell>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            Adhar File :
                                                        </TableCell>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            <MDButton onClick={() => handleDownload(data.adharFileName, data.adharFilePath)}>{data.adharFileName}</MDButton>
                                                        </TableCell>
                                                    </TableRow>                                                   
                                                    <TableRow>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            Status :
                                                        </TableCell>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            {data.status}
                                                        </TableCell>
                                                    </TableRow>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </MDBox>
                                    <MDBox mt={4} mb={1} textAlign="center">
                                        <Table>
                                            <TableRow>
                                                <TableCell>
                                                    <MDButton color="info" onClick={() => submitHandler("Approved")}
                                                        disabled={data.status === "Approved"}>
                                                        Approved
                                                    </MDButton>
                                                </TableCell>
                                                <TableCell>
                                                    <MDButton color="info" onClick={() => submitHandler("Rejected")}
                                                        disabled={data.status === "Rejected"}>
                                                        Rejected
                                                    </MDButton>
                                                </TableCell>
                                                <TableCell>
                                                    <MDButton color="info" onClick={() => printDocument()}>
                                                        PRINT
                                                    </MDButton>
                                                </TableCell>
                                                <TableCell>
                                                    <MDButton color="secondary" onClick={backHandler}>
                                                        Back
                                                    </MDButton>
                                                </TableCell>
                                            </TableRow>
                                        </Table>
                                    </MDBox>
                                </Card>
                            </MDBox>
                        </MDBox>

                    }
                </Card>
            </div>
        </DashboardLayout>
    );
}

export default ViewAdventureApplication;
